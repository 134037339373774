<template>
    <!-- 
        Компонент календарю, дозволяє - 
        - переглянути події в календарі
        - додати події в календарь
        - та багато іншого :)
    -->
    
    <!-- Головний компонент для управління подіями та календарем -->
    
    <b-row>
        <b-card>
            <FullCalendar :options="calendarOptions" ref="fullCalendar"/>
        </b-card>
    </b-row>

    <!-- 
        Виклик вікна створення події
        - для відкриття застосовується перевірка значення showCreateModal
        - @close - подія, яка спрацьовує при закритті картки
        - @getdata - подія, яка спрацьовує при отриманні даних для календарю
        - :date - дата, котру клікнули і котра піде дефолтною під дедлайн
    -->
    <created
      v-if="showCreateModal"
      @close="this.showCreateModal = false"
      @getdata="getdata"
      :date="this.dateForDeadline"
    />

    <!-- 
       Виклик відкриття картки події
       - для відкриття застосовується перевірка значення showOpenModal
       - @close - подія, яка спрацьовує при закритті картки
       - :objCard - параметр-об'єкт з даними по події
       - @getdata - подія, яка спрацьовує при отриманні даних для календарю
    -->
    <viewBox 
      v-if="this.showOpenModal === true"
      @close="this.showOpenModal = false"
      :objCard="this.objEvent"
      @getdata="getdata"
    />

</template>

<script>
// імпорт бібліотек та компонентів для використання FullCalendar
import "@fullcalendar/core/vdom";
import { CalendarIcon } from "@zhuowenli/vue-feather-icons";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import FullCalendar from "@fullcalendar/vue3";
import ruLocale from '@fullcalendar/core/locales/ru';
import ukLocale from '@fullcalendar/core/locales/uk';

import created from "@/views/changelog/new.vue";
import viewBox from "@/views/changelog/view/index.vue";
import modal from '@/components/modal/modal-members' // компонент додавання співробітників
import { storeS } from '@/store' // глобальне сховище даних
import { Updates } from '@/API' // клас для взаємодії з API 
import Filter from "@/components/globaltable/globalFilter.vue"; // фільтр до календаря
import { mutateReleasesCalendar, mutateTypeCalendar } from "@/usabilityScripts/globalMutate";
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Updates();

export default{
    components: {
        FullCalendar,
        CalendarIcon,
        modal,
        Filter,
        viewBox,
        created
    },
    data(){
        return{
            showTypes: false, // прапорець відображення типів події
            dataParams: { // Параметри для фільтрації
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "calendar"
            },
            objParams:{
                page: '1',
                pagelimit: '1000',
                search: '',
                createWorkerId: '',
                update: '',
                pr1: '',
                pr2: '',
                of: 'id', //deadline_date
                ot: 'desc' //asc
            },
            showModal: false, // прапорець відображення вікна списку співробітників
            eventAccess: { // об'єкт доступу до події
                type: '', // тип події
                workers: [] // масив співробітників
            },
            showCreateModal: false, // прапорець відображення вікна створення події
            showOpenModal: false, // прапорець відображення вікна події
            form: {}, // параметри події
            permToEdit: false, // прапорець режиму редагування події
            calendarOptions: { // параметри для відображення календрарю
                locale: ukLocale, // локалізація
                events: [
                    // { id: 1, title: "event 1", date: "2024-12-09" }
                ], // масив подій
                start: null,
                end: null,
                activeDate: null,
                plugins: [
                    dayGridPlugin, // плагін перегляду подій по дням
                    timeGridPlugin, // плагін перегляду події по годинам
                    interactionPlugin, // плагін для відслідковування кліків, розмірів та інше
                    bootstrapPlugin, // тема календаря
                    listPlugin, // плагін перегляд списку відображає події у простому вертикальному списк
                ],
                themeSystem: "bootstrap", // тема календаря
                headerToolbar: { // // кнопки для перемикання між переглядами
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                },
                windowResize: () => { // автоматична адаптація під потрібний розмір
                    this.getInitialView();
                },
                initialView: this.getInitialView(), // початковий вигляд календаря
                initialEvents: [],
                editable: true, // прапорець чи можна змінювати події в календарі
                selectable: true, // прапорець щоб дозволити користувачу робити вибір, клацаючи та перетягуючи
                selectMirror: true,
                dayMaxEvents: true, 
                weekends: true, // вихідні дні
                dateClick: this.dateClicked, // запускається коли користувач натискає на дату або час
                eventClick: this.openEvent, // запускається коли користувач натискає на подію
            },
            currentEvents: [], // масив найближчих подій
            // howModal: false,
            // eventModal: false,
            // submitted: false,
            // submit: false,
            newEventData: '', // Дата створення (при кліку на день)
            dateForDeadline: "",
            event: {
                title: "",
                category: "",
            },
            obj: {},
            objEvent: {},
        }
    },
    created(){
        // ... ініціалізація компонента при створенні
        if(storeS.lang == 'uk'){
            this.calendarOptions.locale = ukLocale
        } else if(storeS.lang == 'ru'){
            this.calendarOptions.locale = ruLocale
        }

        // отримання даних для календаря
        this.getdata()
    },
    methods: {
        mutateColor(e) {
            /**
                * @param {object} e - тип 
            */
            return mutateReleasesCalendar(e)
        },
        getdata(){
            this.calendarOptions.events = [] // чистимо масив
            /**
                Отримує дані про події з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Object} objParams - параметри для отримання даних
            */

            apiServe.getAllUpdates(this.objParams).then(result => {
                if(result.status == 'done'){
                    result.data.items.forEach(el => {
                        this.calendarOptions.events.push({
                            title: el.update,
                            id: el.deadlineId,
                            date: el.deadlineDate,
                            className: this.mutateColor(el.update).color,
                        })
                    })
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання всіх подій'; 
                    var statusRequest = result.status;
                    var api = 'getEvents';
                    var fileRequest = 'src/components/calendar/index.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        mutateType(e, item) {
            /*
                Функція для застосування перекладу до типу події
            */
            return mutateTypeCalendar(e, item)
        },
        getInitialView() {
            /**
                * Метод для отримання початкового вигляду календаря.
                * @returns {string} - Початковий вигляд календаря залежно від ширини вікна.
            */

            if (window.innerWidth >= 768 && window.innerWidth < 1200) {
                return "timeGridWeek";
            } else {
                return "listMonth";
            }
        },
        dateClicked(info) {
            /**
                * Метод для обробки кліку на дату у календарі.
                * @param {Object} info - Об'єкт із інформацією про клікнуту дату.
            */

            console.log(info)
            this.dateForDeadline = info.dateStr.split('T')[0]
            this.showCreateModal = true
        },
        openEvent(info){
            console.log(info)
            /**
                Функція для відкриття модального вікна з інформацією про подію.
                * @param {Object} info - об'єкт картки події

                Результат присвоюється властивості objEvent, та встановлюється прапорці showOpenModal в true для відображення модального вікна.
            */
           
            console.log(info.event._def.publicId)

            apiServe.getUpdate(info.event._def.publicId).then(result => {
                if(result.status === 'done') {
                    console.log('here')
                    this.objEvent = result.data;
                    this.showOpenModal = true;
                }
            })
        },
    },
    mounted() {
        // Cлухання події updateEvents для оновлення даних після змін з карткою події.
        this.eventBus.off('updateEvents');
        this.eventBus.on('updateEvents', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
}

</script>

<style>
.comingEvent {
    height: 600px;
    overflow: auto;
    margin-bottom: 20px;
}

.comingEvent::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .comingEvent::-webkit-scrollbar
    {
        
        height: 5px;
        background-color: #F5F5F5;
    }

    .comingEvent::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #302626af;
    }
.fc-daygrid-event {
    border: none;
}
</style>