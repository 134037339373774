<template>
    <PageHeader :title="title" />

    <Calendar />

</template>

<script>
import PageHeader from "@/components/page-header";
import Calendar from './calendar.vue'

export default {
  data() {
    return {
      title: this.$t('Календар релізів'),
    }
  },
  components: {
    PageHeader,
    Calendar
  },
  created(){
    
  },
  methods: {
    
  },
};
</script>

